import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Servicos from "./pages/Servicos";
import Sobre from "./pages/Sobre";
import ProdutoDiesel from "./pages/Produtos/Diesel";
import ProdutoGas from "./pages/Produtos/Gas";
import ProdutoMaritimo from "./pages/Produtos/Maritimo";

export default function Rotas(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" excat element={ <Home/>} />
                <Route path="/servicos" excat element={ <Servicos/>} />
                <Route path="/sobre" excat element={ <Sobre/>} />
                <Route path="/produto-diesel" excat element={ <ProdutoDiesel/>} />
                <Route path="/produto-gas" excat element={ <ProdutoGas/>} />
                <Route path="/produto-maritimo" excat element={ <ProdutoMaritimo/>} />
            </Routes>
        </BrowserRouter>
    )
}
import React from "react";
import Header from "../../components/header";
import BannerHome from "../../components/banners/home";
import Produtos from "../../components/produtos";
import Servicos from "../../components/servicos";
import Sobre from "../../components/sobre";
import Contato from "../../components/contato";
import Footer from "../../components/footer";
import './style.css'

export default function Home(){
    return(
        <>
        <div className="backgroundS1">
            <Header/>
            <BannerHome/>
        </div>
        <br /> <br />
        <Produtos/>
        <Servicos/>
        <Sobre />
        <Contato />
        <Footer/>
        </>
    )
}
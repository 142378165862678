import React from 'react'
import imgBannerServico from '../../../images/imgs-banner-servico.png'
import iconTel from '../../../images/icon-tel.png'
import './style.css'

export default function BannerServicos(){
    return(
    <>
        <div className='bannerServico'>
            <div className='textsBannerServico'>
                <h5 className="h5Branco500">SERVIÇOS</h5>
                <h1>Consultoria e < br/> manutenção especializada</h1>
                <br />
                {/* <div>
                    <button className='buttonAzul1'>Veja os nossos serviços</button>
                </div> */}
            </div>
            <div className="s2BannerServico">
                <img src={imgBannerServico} alt="" className='imgBannerServico'/>
                <div className='divTel'>
                    <img src={iconTel} alt="" />
                    <div className='textTel'>
                        <h4 className="h4Branco">(71) 3512-6182</h4>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
import React from "react";
import CardProduto from "./card";
import geradorDiesel from '../../images/gerador-diesel-2023.png'
import geradorGas from '../../images/gerador-gas.png'
import geradorMaritimo from '../../images/gerador-maritimo.png'
import './style.css'

export default function Produtos() {
    return (
        <div id="produtos">
            <center>
                <h3>PRODUTOS</h3>
                <h2 className="h2Preto">Conheça nossas soluções</h2>
            </center>

            <div className="cardsProdutos">
                <CardProduto
                    image={geradorDiesel}
                    nome="Gerador a Diesel"
                    link="https://wa.me/557135126182"
                    descricao="Os Grupos Geradores a Diesel fabricados pela MWM são equipados com motores próprios e de grandes parceiros, como Scania, Volvo Penta, MAN e Yanmar"
                />
                <CardProduto
                    image={geradorGas}
                    nome="Gerador a Gás"
                    link="https://wa.me/557135126182"
                    descricao="Os Grupos Geradores a Gás fabricados pela MWM são equipados com motores próprios e de nossa grande parceira Scania."
                />
                <CardProduto
                    image={geradorMaritimo}
                    nome="Gerador Marítimo"
                    link="https://wa.me/557135126182"
                    descricao="Os Grupos Geradores Marítimos fabricados pela MWM, são frutos da parceria com a Northern Lights, conceituada fabricante norte-americana."
                />
            </div>
        </div>
    )
}
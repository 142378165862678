import Header from '../../../components/header'
import TemplatePageProduto from '../../../components/templatePageProduto'
import Contato from '../../../components/contato'
import Footer from '../../../components/footer'
import imgGeradorDiesel from '../../../images/img-gerador-diesel.png'
import './style.css'

export default function ProdutoDiesel(){
    return(
        <>
            <div className="backgroundHeader">
                <Header/>
            </div>
            <div className='backgroundProdutoDiesel'>

            </div>
            <TemplatePageProduto
                title="Gerador a Diesel"
                descricao="Os Grupos Geradores a Diesel fabricados pela MWM são equipados com motores próprios e de grandes parceiros, como Scania, Volvo Penta, MAN e Yanmar"
                caracteristicas=""
                image={imgGeradorDiesel}
            />
            <Contato />
            <Footer />
        </>
    )
}
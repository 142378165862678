import React from 'react'
import { FaEnvelope, FaPhoneAlt, FaMapMarkedAlt, FaLinkedinIn, FaInstagram, FaWhatsapp } from "react-icons/fa";

import './style.css'

export default function Contato() {


    return (
        <div className='contato' id='contato'>
            <center>
                <h3>CONTATO</h3>
                <h2 className='h2Branco'>Fale conosco</h2>
            </center>


            <div className='sContato'>

                {/* <div className='icons-contact'> */}
                {/* <a href="mailto:jeman@jemanconsult.com.br" title="jeman@jemanconsult.com.br"><FaEnvelope className="icon-social-contact" size="25" color="#fff" /></a> */}
                {/* <a href="tel:+557135126182" title="(71) 3512-6182"><FaPhoneAlt className="icon-social-contact" size="25" color="#fff" /></a> */}
                {/* <a href="http://"><FaLinkedinIn className="icon-social-contact" size="25"  color="#fff"/></a> */}
                {/* <a href="http://"><FaInstagram className="icon-social-contact" size="25"  color="#fff"/></a> */}
                {/* <a href="https://wa.me/557135126182" title="(71) 3512-6182"><FaWhatsapp className="icon-social-contact" size="25" color="#fff" /></a> */}
                {/* </div> */}

                <div className='address'>
                    <a href="tel:+557135126182" target="_blank">
                        <FaPhoneAlt size="37" color="#05A3E2" />
                    </a>
                    <a href="tel:+557135126182" className="linkAddress" target="_blank">
                        (71) 3512-6182
                    </a>
                </div>

                <div className='address'>
                    <a href="https://wa.me/557135126182" target="_blank">
                        <FaWhatsapp size="37" color="#05A3E2" />
                    </a>
                    <a href="https://wa.me/557135126182" className="linkAddress" target="_blank">
                        (71) 3512-6182
                    </a>
                </div>

                <div className='address'>
                    <a href="mailto:jeman@jemanconsult.com.br" target="_blank">
                        <FaEnvelope size="37" color="#05A3E2" />
                    </a>
                    <a href="mailto:jeman@jemanconsult.com.br" className="linkAddress" target="_blank">
                        jeman@jemanconsult.com.br
                    </a>
                </div>



                <div className='address'>
                    <a href="https://goo.gl/maps/u6hkcDBx4iK8DrAD9" target="_blank" className="linkAddress">
                        <FaMapMarkedAlt size="37" color="#05A3E2" />
                    </a>
                    <a href="https://goo.gl/maps/u6hkcDBx4iK8DrAD9" className="linkAddress" target="_blank">
                        Rua Jurupoca, 134, Galpão 01, Buraquinho, <br /> Lauro de Freitas/BA - CEP 42710-590
                    </a>
                </div>

            </div>
        </div>
    )
}
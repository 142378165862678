import React from 'react'
import groupImgSobre from '../../../images/group-img-empresa.png'
import CardInfo from './card'
import './style.css'

export default function InfosSobre(){
    return(
        <div className='infosSobre'>
            <h5 className='h5Preto400'>
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its.
            </h5>
            <div className='cardsInfos'>
                <CardInfo nome="Missão" descricao="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layo by the"/>
                <CardInfo nome="Visão" descricao="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layo by the"/>
                <CardInfo nome="Valores" descricao="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layo by the"/>
            </div>
            <div className='groupImgSobre'>
                <img src={groupImgSobre} alt="" className='groupImg' />
            </div>
            
        </div>
    )
}
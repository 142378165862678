import React from "react";
import { Link } from "react-router-dom"
import './style.css'

export default function CardProduto({ image, nome, descricao, link }) {
    return (
        <div className="cardProduto">
            <div>
                <img className="imgProduto" src={image} alt="produto" />
                <h3 className="text-white text-center">{nome}</h3>
                <div>
                    <h5 className="h5Branco text-center">{descricao}</h5>
                </div>
            </div>
            <a className="btn buttonAmarelo linkProduto" href={link} target="_blank">
                {/* <Link to={link} className="linkProduto"> */}
                Fale conosco
                {/* </Link> */}
            </a>
        </div>
    )
}
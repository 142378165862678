import React from "react";
import "./style.css"

export default function CardInfo({nome, descricao}){
    return(
        <div className="cardInfo">
            <h4 className="h4Branco">{nome}</h4>
            <h5 className="h5Branco">{descricao}</h5>
        </div>
    )
}
import React from 'react'
import './style.css'

export default function BannerHome(){
    return(
    <>
        <div className='bannerHome'>
            <h1>
                Somos uma empresa parceira da MWM
                <h4>
                    A JEMAN é revendedora autorizada e especialista em geradores da maior empresa de geradores da América Latina
                </h4>
            </h1>

            <br />
            <h3 className='headerH3Top'>Conheça a seguir os nossos serviços e consulte os nossos especialistas</h3>
            <div className='buttons'>
                <a href="/servicos"><button className='buttonAzul2'>Nossos serviços</button></a>
                <a href="#produtos"><button className='buttonAmarelo'>Nossos produtos</button></a>
            </div>
        </div>
    </>
    )
}
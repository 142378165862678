import React from "react";
import Header from "../../components/header";
import BannerSobre from "../../components/banners/sobre";
import InfosSobre from "./infos";
import Contato from "../../components/contato";
import Footer from "../../components/footer";
import './style.css'

export default function Sobre(){
    return(
        <>
        <div className="backgroundSobre">
            <Header/>
            <BannerSobre/>
        </div>
        <InfosSobre/>
        <Contato />
        <Footer/>
        </>
    )
}
import React from "react";
import "./style.css"

export default function CardServico({ image, nome, descricao }) {
    return (
        <div className="cardServico">
            <img className="iconServico" src={image} alt="servico" />
            <div className="servico">
                <h4>{nome}</h4>
                <h5 className="h5Branco space-15">{descricao}</h5>
            </div>
        </div>
    )
}
import React from "react";
import Header from "../../components/header";
import BannerServicos from "../../components/banners/servico";
import SobreServicos from "./sobreServicos";
import Contato from "../../components/contato";
import Footer from "../../components/footer";
import './style.css'

export default function Servicos(){
    return(
        <>
        <div className="backgroundServico">
            <Header/>
            <BannerServicos/>
        </div>
        <SobreServicos/>
        <Contato />
        <Footer/>
        </>
    )
}
import React from 'react'
import imgSobre from '../../images/jeman-sobre-nos.png'
import './style.css'


export default function Sobre(){
    return(
        <div className='sobre'>
            <div className='s1Sobre'>
                <h5 className='h5Azul'>A EMPRESA</h5>
                <h2>Sobre nós</h2>
                <br/>
                <h4 className='h4Preto space-15'>Criada com o objetivo de oferecer mão de obra qualificada para suprir as demandas de: consultoria, manutenção e instalação de grupos geradores a diesel, gás e marítimo.
                    <br/><br/>
                    Nossa prioridade é atingir a satisfação dos clientes de acordo com as demandas técnicas de cada um.
                    <br/><br/>
                    A experiência da equipe técnica associada ao conhecimento de engenharia, faz da JEMAN uma empresa versátil, com visão holística, agregando áreas como elétrica, mecânica, automação e geração de energia, que é nosso carro chefe.
                </h4>
                <br/>
                <a href="/sobre"><button className='buttonAzul1'>Saiba mais</button></a>
            </div>

            <img className='imgSobre' src={imgSobre} alt="Logo JEMAN" />
        </div>
    )
}
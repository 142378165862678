import React from 'react'
import iconConsultoria from '../../../images/icon-consultoria.png'
import iconManutencao from '../../../images/icon-manutencao.png'
import imgConsultoria from '../../../images/img-consultoria.png'
import imgManutencao from '../../../images/img-manutencao.png'
import './style.css'

export default function SobreServicos() {
    return (
        <div className='sobreServicos'>
            <div className='divConsultoria'>
                <div className='itensConsultoria'>
                    <div className='iconName'>
                        <img src={iconConsultoria} alt="" className='iconServico' />
                        <h3 className='h3Azul'>Consultoria</h3>
                    </div>
                    <div>

                        <h4 className='h4'>Eficiência Energética e Meio Ambiente</h4>
                        <p className='space-15'>
                            Com o objetivo de reduzir o valor unitário pago pelo kWh. A JEMAN busca através de avaliação minuciosa entender e perceber a necessidade do cliente no quesito consumo de energia elétrica, essa avaliação é feita inicialmente através da conta de energia onde identificaremos a melhor solução, seja ela instalação de grupos geradores (diesel ou gás), correção de fator de potencia, instalação de lâmpadas led e estudo horo sazonal, ou ainda uso de energias renováveis como Energia Fotovoltaica.
                        </p>
                        <p className='space-15'>
                            Após a realização deste estudo preliminar, a simulação é apresentada ao cliente, onde constata-se quanto a empresa está pagando e quanto irá pagar com a realização de nossos serviços. Esta simulação preliminar é realizada sem custos ao cliente, bastando enviar-nos uma cópia da última fatura de energia para que os resultados sejam apresentados.
                        </p>

                        <h4 className='h4'>Estudo de Coordenação e Seletividade</h4>
                        <p className='space-15'>
                            A JEMAN realiza estudo de coordenação e seletividade em projetos de instalações elétricas, de acordo com a exigência de cada concessionaria, isso onde é necessário a operação de grupos geradores em paralelismo momentâneo com a rede.
                        </p>
                        <p className='space-15'>
                        A JEMAN também realiza a instalação dos grupos geradores desde a parte civil, passando pela instalação mecânica e elétrica.
                        </p>


                        <h4 className='h4'>Aplicação de Geradores</h4>
                        <p className='space-15'>
                            A JEMAN identifica o regime de operação adequado para cada aplicação, conforme normas vigentes ISO-8528, o regime de aplicação determina qual será a manutenção mais assertiva para cada equipamento, aumentando assim a sua durabilidade e diminuindo a possibilidade de inoperância do sistema, através de falhas inesperadas. REGIMES DE OPERAÇÃO: – STAND BY – PRIME – COP
                        </p>

                    </div>
                </div>
                <img src={imgConsultoria} alt="" className='imgConsultoria' />
            </div>

            <div className='divManutencao'>
                <img src={imgManutencao} alt="" className='imgManutencao' />
                <div className='itensManutencao'>
                    <div className='iconName'>
                        <img src={iconManutencao} alt="" className='iconServico' />
                        <h3 className='h3Azul'>Manutenção</h3>
                    </div>

                    <div>
                        <h4 className='h4'>Manutenção Preventiva</h4>
                        <p className='space-15'>
                            É a manutenção realizada com o foco em reduzir, evitar a quebra ou a queda no desempenho do equipamento. Para isso, utiliza-se um plano antecipado com intervalo de tempo definido conforme a necessidade do cliente visando garantir a durabilidade, confiabilidade e disponibilidade do GMG, reduzindo assim, a incidência de manutenções corretivas que possam provocar a sua inoperância do sistema.
                        </p>

                        <h4 className='h4'>Manutenção Corretiva</h4>
                        <p className='space-15'>
                            Caso seu equipamento apresente algum problema inesperado, mediante chamado avulso, técnicos avaliam o problema por telefone e providenciam o imediato atendimento por profissionais especialistas mecânico ou elétrico. Após o levantamento técnico elaboramos orçamentos detalhados, discriminando serviços, peças e produtos que serão necessários. Após avaliação e aprovação do cliente executamos a manutenção.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}
import React from 'react'
import imgBannerSobre from '../../../images/jeman-sobre-nos-2.png'

import './style.css'

export default function BannerSobre(){
    return(
    <>
        <div className='bannerSobre'>
            <div className='textsBannerSobre'>
                <h5 className="h5Branco500">A EMPRESA</h5>
                <h1>Conheça a JEMAN, <br /> sua missão, visão e valores</h1>
            </div>
            <div>
                <img src={imgBannerSobre} alt="" className='imgBannerSobre'/>
            </div>
        </div>
    </>
    )
}
import React from "react";
import "./style.css"
import CardServico from "./card";
import imgConsultoria from '../../images/icon-consultoria.png'
import imgManutencao from '../../images/icon-manutencao.png'
import bannerServicos from '../../images/grupo-img-servico.png'


export default function Servicos() {
    return (
        <div className="backgroundS2">
            <div className="s1Servico">
                <div className="textsServico">
                    <h3 >SERVIÇOS</h3>
                    <h2 className="h2Branco">Consultoria e manutenção especializada</h2>
                </div>
                <br />
                <CardServico image={imgConsultoria} nome="Consultoria" descricao="Eficiência energética, estudo de coordenação e seletividade, aplicação de geradores, estamos prontos para planejar da melhor forma a sua planta energetica." />
                <CardServico image={imgManutencao} nome="Manutenção" descricao="Planejar a manutenção dos seus grupos energéticos irá manter os custos sob controle, estamos prontos para realizar as manutenções preventivas dos seus equipamentos. Mas se algo der errado e precisar de apoio de última hora, estamos de prontidão. " />
                <br /><br />
                <a href="/#servicos"><button className="buttonAzul1">Saiba mais</button></a>
            </div>
            <div>
                <img className="bannerServicos" src={bannerServicos} alt="Banner Serviços" />
            </div>
        </div>
    )
}
import React from 'react'
import logo from '../../images/logo.png'

import './style.css'

export default function Footer(){
    return(
        <>
            <footer>
                <img src={logo} alt="Logo JEMAN" className='logo'/>
                <h5 className='h5Preto'>2022. Todos os direitos reservados</h5>
            </footer>
        </>
    )
}
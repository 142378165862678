
import iconTel from '../../images/icon-tel.png'

import './style.css'


export default function TemplatePageProduto({title, descricao, caracteristicas, image}){
    return(
        <div className='templatePageProduto'>
            <div className="s1Produto">
                <div className="textsS1Produto">
                    <div>
                        <h3 className='h3Azul'>{title}</h3>
                        <h5 className='h5Preto'>{descricao}</h5>
                    </div>
                    <div>
                        <h3 className='h3Azul'>Características</h3>
                        <h5 className='h5Preto'>{caracteristicas}</h5>
                    </div>
                </div>
                <div>
                    <img src={image} alt="" className='imgS1Produto'/>
                    <div className='divTelProduto'>
                        <img src={iconTel} alt="" />
                        <div className='textTelProduto'>
                            <h4 className="h4Branco">(71) 3512-6182</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='s2Produto'>
                <h4 className="h4Branco">Selecione abaixo as opções desejadas para encontrar o seu gerador:</h4>
            </div>
            <div className='s3Produto'>
                <select name="" id="">
                    <option value="60hz">60Hz</option>
                    <option value="50hz">50Hz</option>
                </select>
                <select name="" id="">
                    <option value="MAN">MAN</option>
                    <option value="MWM">MWM</option>
                    <option value="SCANIA">SCANIA</option>
                    <option value="VOLVO">VOLVO</option>
                    <option value="YANMAR">YANMAR</option>
                </select>
            </div>
            <div className='s4Produto'>
                <div className="space">
                    <h5 className='h5BrancoBgAzulCod'>Código</h5>
                </div>
                <div className="space">
                    <div>
                        <h5 className='h5BrancoBgAzulPotEsp'>Potência (kVA)</h5>
                    </div>
                    <div className='tiposEsp'>
                        <div>
                            <h5 className='h5BrancoBgAzulTipo'>ESP</h5>
                        </div>
                        <div>
                            <h5 className='h5BrancoBgAzulTipo'>PRP</h5>
                        </div>
                        <div>
                            <h5 className='h5BrancoBgAzulTipo'>COP</h5>
                        </div>
                    </div>
                </div>
                <div className="space">
                    <div>
                        <h5 className='h5BrancoBgAzulPotEsp'>Especificação Técnica</h5>
                    </div>
                    <div className='tiposEsp'>
                        <div>
                            <h5 className='h5BrancoBgAzulTipo'>Motor</h5>
                        </div>
                        <div>
                            <h5 className='h5BrancoBgAzulTipo'>Modelo</h5>
                        </div>
                        <div>
                            <h5 className='h5BrancoBgAzulTipo'>Lâminas Técnicas</h5>
                        </div>
                    </div>
                </div>
                <div className="space">
                    <h5 className='h5BrancoBgAmareloOrc'>Solicitar orçamento</h5>
                </div>
            </div>
        </div>
    )
}
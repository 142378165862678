import React, { useState } from 'react'
import logo from '../../images/logo.png'
import {MdMenu, MdClose} from 'react-icons/md'
import { Link } from "react-router-dom"
import './style.css'

export default function Header(){

    const [openMenu, setOpenMenu] = useState(false)

    // const navigate = useNavigate()

    // function handleHome(){
    //     navigate('/')
    // }

    // function handleServicos(){
    //     navigate('/servicos')
    // }

    // function handleSobre(){
    //     navigate('/sobre')
    // }

    const handleOpen = () => {
        setOpenMenu(!openMenu)
    }



    return(
        <header>
            <div>
                <img src={logo} alt="logo" className='logo' />
            </div>

            {
                openMenu !== true
                ?
                <MdMenu className="iconMenu" size="45" color="#fff" onClick={handleOpen}/>
                :
                <MdClose className="iconMenu" size="45" color="#fff" onClick={handleOpen}/>
            }

            <nav className={openMenu !== true ? "itensHeader" : "open"}>
                <ul>
                    <Link to='/' className='link'><li>Início</li></Link>
                    <a href="/#produtos" className='link'><li>Produtos</li></a>
                    <Link to='/servicos' className='link'><li>Serviços</li></Link>
                    <Link to='/sobre' className='link'><li>A empresa</li></Link>
                </ul>
            </nav>
            <div className={openMenu !== true ? "itensHeader" : "open"}>
                <a href="#contato"><button className='buttonAzul1'>Fale conosco</button></a>
            </div>
        </header>
    )
}
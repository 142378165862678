import Header from '../../../components/header'
import TemplatePageProduto from '../../../components/templatePageProduto'
import Contato from '../../../components/contato'
import Footer from '../../../components/footer'
import imgGeradorMaritimo from '../../../images/img-gerador-maritimo.png'
import './style.css'

export default function ProdutoMaritimo(){
    return(
        <>
            <div className="backgroundHeader">
                <Header/>
            </div>
            <div className='backgroundProdutoMaritimo'>
                
            </div>
            <TemplatePageProduto 
                title="Gerador Maritimo" 
                descricao="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its."
                caracteristicas="It is a long established fact that a reader It is a long established fact that a reader It is a long established fact that a reader It is a long established fact that a reader"
                image={imgGeradorMaritimo}
            />
            <Contato />
            <Footer />
        </>
    )
}
// import logo from './logo.svg';

import Rotas from './rotas';

function App() {
  return (
    <>
      <Rotas />
    </>
  );
}

export default App;
